import img1 from '/src/img/1.jpg';
import img2 from '/src/img/2.jpg';
import img3 from '/src/img/3.jpg';
import img4 from '/src/img/4.jpg';
import img5 from '/src/img/5.jpg';
import img6 from '/src/img/6.jpg';
import img7 from '/src/img/7.jpg';
import img8 from '/src/img/8.jpg';
import img9 from '/src/img/9.jpg';
import img10 from '/src/img/10.jpg';
import img11 from '/src/img/11.jpg';
import img12 from '/src/img/12.jpg';
import img13 from '/src/img/13.jpg';
import img14 from '/src/img/14.jpg';
import img15 from '/src/img/15.jpg';
import img16 from '/src/img/16.jpg';
import img17 from '/src/img/17.jpg';
import img18 from '/src/img/18.jpg';
import img19 from '/src/img/19.jpg';
import img20 from '/src/img/20.jpg';
import img21 from '/src/img/21.jpg';
import img22 from '/src/img/22.jpg';
import img23 from '/src/img/23.jpg';
import img24 from '/src/img/24.jpg';
import img25 from '/src/img/25.jpg';



// Array de URLs de las imágenes de fondo
var imagenes = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
  img21,
  img22,
  img23,
  img24,
  img25
];

// Función para cambiar la imagen aleatoriamente
function cambiarImagenAleatoria() {
  // Obtener un índice aleatorio dentro del rango de la longitud del array
  var indiceAleatorio = Math.floor(Math.random() * imagenes.length);
  
  // Obtener la URL de la imagen correspondiente al índice aleatorio
  var nuevaImagen = imagenes[indiceAleatorio];
  
  // Seleccionar la etiqueta img dentro de la sección con la clase "img-container"
  var imgElement = document.querySelector('.img-container img');
  
  // Cambiar el atributo src de la imagen
  imgElement.src = nuevaImagen;
  
  // Llamar a la función para cambiar la imagen nuevamente después de un segundo
  setTimeout(cambiarImagenAleatoria, 180); // 1000 milisegundos = 1 segundo
}

// Llamar a la función para cambiar la imagen aleatoriamente
cambiarImagenAleatoria();
